
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import Item1Vue from "@/layout/header/partials/activity-timeline/Item1.vue";

export default defineComponent({
  components: {},
  prop: ['posts'],

  data() {
    return {
      posts: [],
      tableData: [],
      schoolId: '',
      send: '',
    }
  },

  // methods: {
  //   getPosts() {
  //     this.posts = data; 
  //   }
  // }

  // mounted() {
  //   if (this.datas) {
  //     this.tableData = this.datas  
  //   }
  // },
  // beforeMount() {

  //   store.dispatch(Actions.VERIFY_AUTH_FINANCE);

  //   setCurrentPageBreadcrumbs("Finance", ["Data"]);
  // },

});
